import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import View360, { EquirectProjection } from "@egjs/react-view360";
import "@egjs/react-view360/css/view360.min.css";
import "./App.css";

export default function App() {

  const [currentImage, setCurrentImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const view360Ref = useRef(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get("/Panorama_Suitcase Type 01 (Fur)_2023-08-21-15-46-35.jpg", {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
          }
        });
        const blobURL = URL.createObjectURL(response.data);
        setCurrentImage(blobURL);
      } catch (error) {
        console.error("Error fetching the image:", error);
      }
    };

    fetchImage();
  }, []);

  useEffect(() => {
    const currentRef = view360Ref.current; // Capture the current value of the ref

    if (currentRef) {
      const handleReady = () => {
        console.log("Ready event fired!"); // Log when the ready event is fired
        setIsLoading(false); // Hide loading screen once the image is rendered
      };
      currentRef.on("ready", handleReady);
      return () => {
        currentRef.off("ready", handleReady); // Use the captured value in the cleanup function
      };
    } else {
      console.log("View360 component not rendered yet."); // Log if the View360 component hasn't been rendered
    }
  }, []);

  useEffect(() => {
    const currentRef = view360Ref.current;

    if (currentRef) {
      console.log("Attaching event listener to View360 component.");

      const handleReady = () => {
        console.log("Ready event fired!");
        setIsLoading(false);
      };

      currentRef.on("ready", handleReady);

      return () => {
        currentRef.off("ready", handleReady);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view360Ref.current]);


  const projection = useMemo(() => {
    if (!currentImage) return null;
    return new EquirectProjection({
      src: currentImage
    });
  }, [currentImage]);

  if (!currentImage) return null;

  /*
  const handleHotspotClick = () => {
    if (currentImage === "/Panorama_Suitcase Type 01 (Fur)_2023-08-21-15-46-35.jpg") {
      setCurrentImage("/Panorama_Suitcase Type 01 (Fur)_2023-08-21-15-46-35.jpg");
    } else {
      setCurrentImage("/Panorama_Suitcase Type 01 (Fur)_2023-08-21-15-46-35.jpg");
    }
  }
  */

  return (
    <div>
      {/*isLoading &&
        <div className="loading-screen">
          {progress < 100 ? `Loading... ${progress}%` : "Rendering..."}
        </div>
      */} {/* Display loading screen */}
      <View360
        className="is-16by9"
        projection={projection}
        //fov={110}
        control={{ autoRotate: false }} // Disable auto-rotation
        ref={view360Ref}
      >
        {/*<div className="view360-hotspots">
        <div className="view360-hotspot" data-yaw="160000" data-pitch="-100" onClick={handleHotspotClick}>
           You can add any content inside the hotspot 
          Hotspot 1
        </div>
        <div className="view360-hotspot" data-yaw="15" data-pitch="-15" onClick={handleHotspotClick}>

          Hotspot 2
        </div>

      </div> */}
      </View360>
    </div>
  );
}
